import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import sanityClient from '../../sarlperrollazgestion/sanity.cli.js'
import NavBar from "../../components/navBar/NavBar";


import "./devis.css";

import imgLogo from '../../style/mesimages/logo.png';

const Devis = () => {
    // const builder = imageUrlBuilder(defineCliConfig);
    // const urlFor = (source) => builder.image(source);

    const { slug } = useParams();

    const [devisData, setDevis] = useState(null);
    const [clientData, setClient] = useState([]);
    const [currentClient, setCurrentClient] = useState("undifined");
    const [nbDevis, setNbDevis] = useState(0);
    const [nbDoc, setNbDoc] = useState(0);
    const [isLogged, setIsLogged] = useState(false);

    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");

    const logClient = () => {
        console.log("clientData", clientData);
        console.log(inputEmail, inputPassword);
        console.log(clientData.email, clientData.password);

        if (inputEmail && inputPassword) {
            if (inputEmail === clientData[0].email && inputPassword === clientData[0].password) {
                localStorage.setItem('password', inputPassword);
                setIsLogged(true);
            }
        }
    }

    const downloadFile = (fileData) => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${fileData}`;

        link.download = 'document.pdf';
        link.click();
    };

    useEffect(() => {
        console.log("react app:", process.env.REACT_APP_API_URL);
        fetch(`${process.env.REACT_APP_API_URL}/clients/${slug}`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setClient(data);
            })
            .catch(console.error);
    }, [slug]);

    useEffect(() => {
        if (clientData.length > 0) {
            if (localStorage.getItem('password') === clientData[0]?.password) {
                setIsLogged(true);
            }
        }
    }, [clientData]);


    useEffect(() => {
        if (isLogged && clientData) {
            fetch(`${process.env.REACT_APP_API_URL}/files/${clientData[0].id}`)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    setDevis(data);
                })
                .catch(console.error);
        }
    }, [isLogged]);

    return (
        <div className="espace_client">

            <NavBar />
            {
                !isLogged && (
                    <div className="not_logged_back">
                        <div className="not_logged">
                            <h2>Veuillez vous connecter pour accéder à votre espace client</h2>

                            <div>
                                <input type="text" placeholder="email" onChange={(e) => setInputEmail(e.target.value)} />
                                <input type="password" placeholder="password" onChange={(e) => setInputPassword(e.target.value)} />
                                <button
                                    onClick={() => logClient()}
                                >
                                    Se connecter
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                isLogged && (
                    <div>

                        <h1>Bienvenue sur votre espace client {clientData[0].name}</h1>
                        <h4>
                            Retrouvez ici les différents devis et documents lié à votre compte client.
                        </h4>

                        <ul className="liste_document">
                            <li className="item_doc">
                                <h2>Voici la liste de vos devis en cours:</h2>
                                <div className="liste_doc">
                                    {devisData &&
                                    (devisData
                                        .filter((devis) => devis.type.includes("devis"))
                                        .length === 0 ? (
                                        <p>Vous n'avez pas de devis en cours</p>
                                    ) : (
                                        devisData
                                            .filter((devis) => devis.type.includes("devis"))
                                            .map((devis, index) => (
                                                <li key={index} className="a_doc" >
                                                    <h3 className="title_doc">{devis.nom_du_fichier}</h3>
                                                    {devis.fichier && (
                                                        <button
                                                            onClick={() => downloadFile(devis.fichier)}
                                                        >
                                                            Télécharger le document
                                                        </button>
                                                    )}
                                                </li>
                                            ))
                                    ))}
                                </div>
                            </li>

                            <li className="item_doc">
                                <h2>Voici la liste de vos documents en cours:</h2>
                                <div className="liste_doc">
                                    {devisData &&
                                    (devisData
                                        .filter((devis) => !devis.type.includes("devis"))
                                        .length === 0 ? (
                                        <p>Vous n'avez pas de nouveau document</p>
                                    ) : (
                                        devisData
                                            .filter((devis) => !devis.type.includes("devis"))
                                            .map((devis, index) => (
                                                <li key={index} className="a_doc" >
                                                    <h3 className="title_doc">{devis.nom_du_fichier}</h3>
                                                    {devis.fichier && (
                                                        <button
                                                            onClick={() => downloadFile(devis.fichier)}
                                                        >
                                                            Télécharger le document
                                                        </button>
                                                    )}
                                                </li>
                                            ))
                                    ))}
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            }

            <footer id="footer" class="bg-color-secondary border-top-0 mt-0 custom-footer">
                <div class="container container-xl-custom py-md-4">
                    <div class="row justify-content-md-center py-5">
                        <div class="col-md-12 col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
                            <a href="#"><img src={imgLogo} alt="Logo" class="img-fluid logo" /></a>
                        </div>
                        <div class="col-md-3 text-center text-md-start">
                            <p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Nous Retrouver</p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Adresse</p>
                            <p class="text-3 mb-2 text-color-light">13 rue du Chambré, 54760, Faulx - France</p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">TELEPHONE</p>
                            <p class="text-3 mb-2 text-color-light"><a href="tel:+1234567890" class="text-color-light">03 83 49 65 54</a></p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">EMAIL</p>
                            <p class="text-3 mb-2 "><a href="mailto:info@porto.com" class="text-color-light">contact@sarlperrollaz.fr</a></p>
                            <p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Heures d'ouverture</p>
                            <p class="text-3 mb-3 text-color-light">Lun - Ven | 8:30 - 12:30 / 14:00 - 17:00</p>
                            <ul class="social-icons social-icons-dark social-icons-clean">
                                <li class="social-icons-linkedin">
                                    <a href="https://www.linkedin.com/in/didier-perrollaz-568b4b140/" target="_blank" title="Linkedin">
                                        <i class="fab fa-linkedin font-weight-semibold"></i>
                                    </a>
                                </li>
                                <li class="social-icons-facebook">
                                    <a href="https://www.facebook.com/Perrollaz54/" target="_blank" title="Facebook">
                                        <i class="fab fa-facebook-f font-weight-semibold"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4 text-center text-md-start mt-5 mt-md-0 mb-5 mb-lg-0">
                            <p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Navigation</p>
                            <div class="row opacity-7">
                                <div class="col-md-5">
                                    <p class="mb-0"><a href="#" class="text-3 text-color-light link-hover-style-1">Accueil</a></p>
                                    <p class="mb-0"><a href="/contact" class="text-3 text-color-light link-hover-style-1">Nous contacter</a></p>
                                    <p class="mb-0"><a href="/informations" onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')} class="text-3 text-color-light link-hover-style-1">à propos</a></p>
                                    <p class="mb-0"><a href="/login" class="text-3 text-color-light link-hover-style-1">Se connecter</a></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="container container-xl-custom">
                    <div class="footer-copyright footer-copyright-style-2 bg-color-secondary">
                        <div class="py-2">
                            <div class="row py-4">
                                <div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                                    <p class="text-3 text-color-light opacity-7">Tal-Web construction. © 2023. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Devis;